import {http, problemAwareHttp} from '@/http';
import { pathOr } from 'ramda';

export default class ThirdPartiesService {
  /**
   * @typedef ThirdParty
   * @property {String} fileId
   * @property {String} filename
   * @property {String} type
   */

  /**
   * Download a file by deployment ID, third party type, and file ID.
   *
   * @param {String} deployment_id - UUID of the deployment.
   * @param {String} third_party_type - Type of third parties (trusted-certificates, ...).
   * @param {String} file_id - UUID of the file to download.
   * @returns {Promise<AxiosResponse<Blob>>} - Axios response with the file data as a Blob.
   */
  static downloadFile(deployment_id, third_party_type, file_id) {
    return problemAwareHttp.get(`/deployments/${deployment_id}/third-parties/${third_party_type}/${file_id}`, {
      responseType: 'blob', // Specify that the response should be treated as a Blob.
      headers: {
        Accept: 'application/octet-stream', // Set the Accept header to request 'application/octet-stream'.
      },
    });
  }

  /**
   *
   * @param {String} deployment_id - uuid
   * @return {Promise<Array<Object>>} - list of deployments inside a Promise
   */
  static listAll(deployment_id) {
    return problemAwareHttp
      .get(`/deployments/${deployment_id}/third-parties/`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  /**
   *
   * @param {String} deployment_id - uuid
   * @param {String} third_party_type - Type of third parties (trusted-certificates, ...)
   * @return {Promise<Array<Object>>} - list of deployments inside a Promise
   */
  static listByType(deployment_id, third_party_type) {
    return problemAwareHttp
      .get(`/deployments/${deployment_id}/third-parties/`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((res) => {
        const filteredData = res.data.filter((item) => item.type === third_party_type);
        return filteredData;
      });
  }

  /**
   *
   * @param {String} deployment_id - uuid
   * @param {String} third_party_id - uuid
   * @param {String} third_party_type - Type of third parties (trusted-certificates, ...)
   * @return {Promise<AxiosResponse<any>>}
   */
  static remove(deployment_id, third_party_id, third_party_type) {
    return problemAwareHttp.delete(`/deployments/${deployment_id}/third-parties/${third_party_type}/${third_party_id}`, {});
  }

  /**
   *
   * @param {String} deployment_id - uuid
   * @param {File} file
   * @param {Maybe<ThirdParty>} third_party
   * @param {String} third_party_type - Type of third parties (trusted-certificates, ...)
   * @return {Promise<AxiosResponse<any>>}
   */
  static create(deployment_id, file, third_party, third_party_type) {
    const formData = new FormData();
    formData.append('content', file);

    third_party.map(() => {
      // in replace mode, use the new file name
      formData.append('content', file);
    });

    return problemAwareHttp[third_party.isJust ? 'put' : 'post'](
      `/deployments/${deployment_id}/third-parties/${third_party_type}` +
        third_party.map((thp) => `/${thp.fileId}`).getOrElse(''),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }
}

<template>
  <ciam-card :bg="'white'">
    <ciam-card-content-lines>
      <ciam-card-header>
        <template v-slot:header>{{ $t('deployment.audits.header') }}</template>
        <template v-slot:subtitle>{{ $t('deployment.audits.subtitle') }}</template>
      </ciam-card-header>
      <div>
        <Promised :promise="loading">
          <template v-slot:pending>
            <slot name="loading"></slot>
          </template>

          <template v-slot:rejected="problem">
            <ciam-alert
              v-if="(problem.type = 'not-available-on-current-plan')"
              :title="problem.title"
              :description="getUpsellAlertDescription()"
              :type="AlertStatus.WARNING_HREF"
              :href-text="$t('upsell.email.contact')"
              :href="getUpsellAlertHref()"
            />
          </template>
          <ciam-card-content-line type="full-width">
            <template v-slot:content>
              <div class="flex flex-row justify-between">
                <div class="mt-5 mb-5 flex flex-row gap-2 items-center justify-start align-middle">
                  <ciam-input
                    type="text"
                    v-model="filter"
                    :debounceTimeout="100"
                    date-e2e="refresh-deployment-list"
                    :placeholder="$t('deployment.audits.filter')"
                    icon="fa-search"
                  >
                    <template v-slot:displayIcon
                    />
                  </ciam-input>
                </div>
                <div class="mt-5">
                  <ciam-button
                    class="white"
                    @click="
                  refresh();
                  rotateIcon();
                "
                  >
                    <ciam-icon name="fa-sync-alt" id="rotateMe" :class="{ 'rotate-animation': isRotating }"></ciam-icon>
                  </ciam-button>
                </div>
              </div>

              <b-table
                style="clear: both"
                :striped="true"
                :hoverable="true"
                :data="groupedAudits"
                :aria-next-label="$t('nextPage')"
                :aria-previous-label="$t('previousPage')"
              >
                <template v-for="group in groupedAudits">
                  <template v-if="group.label">
                    <div class="h-10 align-center flex items-center border-b-2 font-medium" :key="group.label">
                      {{ group.label | uppercase }}
                    </div>
                    <div
                      v-for="row in group.rows"
                      :key="row.id"
                      class="py-2 text-gray-500 hover:bg-gray-50 hover:text-gray-800"
                    >
                      <div class="flex flex-row gap-4">
                        <div class="flex-none w-22">
                          <p>{{ row.occurredAt | localeTime(true) }} UTC</p>
                        </div>
                        <div class="flex-none w-16 bg-gray-100 rounded-md text-gray-600 text-center">
                          {{ $t('deployment.audits.actionsList.' + row.action) }}
                        </div>
                        <div class="flex-grow">
                          <p>{{ row.message }}</p>
                        </div>
                        <div class="flex-none w-2/12 truncate text-right" :title="row.emitter">{{ row.emitter }}</div>
                      </div>
                    </div>
                  </template>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>{{ $t('deployment.audits.none') }}</p>
                    </div>
                  </section>
                </template>
              </b-table>
              <div class="py-2" style="text-align: center">
                <ciam-button v-if="hasMore" @click="showMore()">
                  <ciam-icon name="fa-circle-down"></ciam-icon>
                  {{ $t('deployment.audits.more') }}
                </ciam-button>
              </div>
            </template>
          </ciam-card-content-line>

        </Promised>
      </div>
    </ciam-card-content-lines>
  </ciam-card>
</template>

<script>
import DeploymentService from '@/pages/deployments/DeploymentService';
import CiamInput from '@/components/CiamInput';
import moment from 'moment';
import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';
import PlanService from '@/pages/PlanService';
import UpsellFeatureService from '@/pages/UpsellFeatureService';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import { makeQueryablePromise } from '@/util/promise';

export default {
  name: 'DeploymentAudits',
  components: { CiamInput, CiamAlert },
  data() {
    return {
      featureName: 'Audit logs',
      AlertStatus: AlertStatus,
      selectedActions: [],
      audits: [],
      filter: '',
      nextSliceUrl: undefined,
      isRotating: false,
      loading: makeQueryablePromise(Promise.reject()),
    };
  },
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.refresh();
    const datePickerElement = document.getElementById('datepicker');

    if (datePickerElement) {
      const picker = new easepick.create({
        element: document.getElementById('datepicker'),
        css: [
          'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.1/dist/index.css',
          'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.1/dist/index.css',
        ],
        plugins: [RangePlugin],
        setup(picker) {
          picker.on('select', (e) => {
            const { start, end } = e.detail;
          });
        },
      });
    }
  },
  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(this.featureName);
    },
    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(this.featureName);
    },
    rotateIcon() {
      this.isRotating = true;
      const icon = document.getElementById('rotateMe');

      setTimeout(() => {
        this.isRotating = false;
      }, 1000); // 1s
    },
    refresh() {
      this.loading = DeploymentService.getAudits(this.$route.params.id).then(([next, audits]) => {
        this.audits = audits;
        this.nextSliceUrl = next;
      });
    },
    showMore() {
      DeploymentService.getAudits(this.$route.params.id, this.nextSliceUrl).then(([next, audits]) => {
        this.audits.push(...audits);
        this.nextSliceUrl = next;
      });
    },
  },
  computed: {
    groupedAudits() {
      const grouped = [];
      let currentGroup = null;

      // Sort audit per date
      const sortedAudits = this.filtered.slice().sort((a, b) => {
        return moment(b.occurredAt) - moment(a.occurredAt);
      });

      sortedAudits.forEach((audit) => {
        const auditDate = moment(audit.occurredAt).startOf('day');

        if (!currentGroup || !auditDate.isSame(currentGroup.date, 'day')) {
          // If the audit date is different from the date of the current group
          // or if there is no current group, create a new group.

          // Today group
          if (auditDate.isSame(moment().startOf('day'), 'day')) {
            currentGroup = { label: this.$t('deployment.audits.today'), date: auditDate, rows: [] };
            // Yesterday group
          } else if (auditDate.isSame(moment().subtract(1, 'days').startOf('day'), 'day')) {
            currentGroup = { label: this.$t('deployment.audits.yesterday'), date: auditDate, rows: [] };
            // One date per day
          } else {
            currentGroup = { label: auditDate.format('YYYY-MM-DD'), date: auditDate, rows: [] };
          }
          grouped.push(currentGroup);
        }

        currentGroup.rows.push(audit);
      });

      return grouped;
    },
    hasMore() {
      return this.nextSliceUrl !== undefined;
    },
    filtered() {
      if (this.filter.length > 0) {
        return this.audits.filter((event) => JSON.stringify(event).toLowerCase().includes(this.filter.toLowerCase()));
      }
      return this.audits;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.rotate-animation {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

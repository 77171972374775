<template>
  <configuration-panel
    :header="$t('deployment.configuration.env.title')"
    :promise="promise"
    :saving-promise="savingPromise"
    :deploymentStatus="this.deployment.status"
    @save="save()"
    :featureName="$t('deployment.configuration.env.featureName')"
    anchor="#environment-variables">
    <template v-slot:subtitle>
      <ciam-text>{{ $t('deployment.configuration.env.subtitle') }}</ciam-text>
    </template>
    <template>
      <ciam-card-content-line type="split-content-component">
        <template v-slot:label>
          <ciam-text>{{ $t('deployment.configuration.env.variables.label') }}</ciam-text>
        </template>
        <template v-slot:content>
          <ciam-key-value
            :value="envVariables"
            @input="envVariables = $event"
            :key-placeholder="$t('deployment.configuration.env.variables.key')"
            :value-placeholder="$t('deployment.configuration.env.variables.value')"
            class="environment-variables"
            :disabled="deployment.status !== 'RUNNING'"
          />
        </template>
      </ciam-card-content-line>
    </template>
  </configuration-panel>
</template>

<script>
import EnvironmentVariablesService from './EnvironmentVariablesService';
import ConfigurationPanel from '@/pages/deployments/configurations/ConfigurationPanel';
import { AlertStatus } from '@/components/CiamAlert';
import CiamKeyValue from '@/components/CiamKeyValue';
import { Notification } from 'vue-notifyjs';
import { is } from 'ramda';

export default {
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  name: 'EnvironmentVariables',
  components: {
    CiamKeyValue,
    ConfigurationPanel,
  },
  data() {
    return {
      applyFalse: false,
      AlertStatus: AlertStatus,
      promise: Promise.resolve(),
      envVariables: [],
      isSaving: false,
      savingPromise: Promise.resolve(),
    };
  },
  mounted() {
    this.getAll();
  },
  updated() {
  },

  methods: {
    getAll() {
      this.promise = EnvironmentVariablesService.getAll(this.deployment.id).then(
        (envVariables) => {
          this.envVariables = envVariables.map((e) => {
            if (e.name === 'KC_HTTP_RELATIVE_PATH') {
              return {
                ...e,
                warning: 'Updating this will changes the url of your instance.',
                documentation: 'https://documentation.cloud-iam.com/how-to-guides/environment-variables.html#kc-http-relative-path',
              };
            } else {
              return e;
            }
          });
        },
      );
    },
    save() {
      this.isSaving = true;

      let preparedEnvVariables = JSON.parse(JSON.stringify(this.envVariables));

      // Remove value field if type is secret and the value is the default value returns by the api
      preparedEnvVariables = preparedEnvVariables.filter(envVar => {
        if (envVar.type === 'secret' && envVar.value === null) {
          delete envVar.value;
        }
        return envVar.name !== '';
      });

      this.savingPromise = EnvironmentVariablesService.save(this.deployment.id, preparedEnvVariables)
        .then(
          (/*_*/) => {
            Notification.notify({
              message: this.$t('deployment.configuration.notification'),
              type: 'success',
            });
          },
          (err) => {
            Notification.notify({
              message:
                is(Object, err) && err.detail // it seems to be a problem
                  ? this.$t('deployment.configuration.env.update.error', { title: err.title, detail: err.detail })
                  : // it does not seem to be a problem, fallback:
                  this.$t('deployment.configuration.env.update.error', { title: err, detail: '' }),
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.isSaving = false;
          this.getAll();
        });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
}

.environment-variables {
  @apply w-3/4 m-auto;
}
</style>

import { problemAwareHttp } from '@/http';

export class KeycloakFlag {
  /**
   *
   * @param keycloakFlagId {String}
   * @param description  {String}
   * @param enabled  {Boolean}
   */
  constructor(keycloakFlagId, description, enabled) {
    this.keycloakFlagId = keycloakFlagId;
    this.name = name;
    this.description = description;
    this.enabled = enabled;
  }
}

export class SetKeycloakFlag {
  /**
   *
   * @param keycloakFlagId {String}
   * @param status {String}
   */
  constructor(keycloakFlagId, status) {
    this.keycloakFlagId = keycloakFlagId;
    this.status = status;
  }
}

export default class KeycloakFlagService {
  /**
   *
   * @param deploymentId {String}
   * @returns {Promise<AxiosResponse<KeycloakFlag[]>>}
   */
  static async getKeycloakFlags(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/keycloak-flags/`).then((result) => result.data);
  }

  /**
   *
   * @param deploymentId {String}
   * @param configuration {SetKeycloakFlag[]}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static setKeycloakFlags(deploymentId, configuration) {
    return problemAwareHttp.put(`/deployments/${deploymentId}/keycloak-flags/`, configuration).then((response) => {
      if (response.headers['x-tracking-url']) {
        window.open(response.headers['x-tracking-url']);
      }
      return response;
    });
  }
}

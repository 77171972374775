<template>
  <ciam-card>
    <ciam-card-header>
      <template v-slot:header>
        <a :href="anchor">
          <ciam-icon class="text-gray-400 h-4 w-4 cursor-pointer" name="fa-link"></ciam-icon>
        </a>
        {{ header }}
      </template>
      <template v-slot:subtitle>
        <slot name="subtitle"></slot>
      </template>
    </ciam-card-header>
    <ciam-card-content>
      <ciam-card-content-lines>
        <div v-if="showUpgradePlanNotice">
          <!-- not applicable at this point, work is needed in the backend firstly -->
          <ciam-card-content-line type="full-width">
            <template v-slot:content>
              <ciam-card>
                <ciam-card-header class="upgrade-plan">
                  <template v-slot:header v-text="$t('deployment.configuration.upgradePlan.header')"></template>
                  <template v-slot:subtitle v-text="$t('deployment.configuration.upgradePlan.subtitle')"></template>
                  <template v-slot:actions>
                    <ciam-button class="secondary">
                      {{ $t('deployment.configuration.upgradePlan.cta') }}
                      <template v-slot:right>
                        <ciam-icon name="fa-envelope"></ciam-icon>
                      </template>
                    </ciam-button>
                  </template>
                </ciam-card-header>
              </ciam-card>
            </template>
          </ciam-card-content-line>
        </div>
        <Promised v-else :promise="promise">
          <template v-slot:rejected="problem">
            <div>
              <ciam-alert
                v-if="problem.type === 'not-available-on-current-plan'"
                :title="problem.title"
                :description="getUpsellAlertDescription()"
                :type="AlertStatus.WARNING_HREF"
                :href-text="$t('upsell.email.contact')"
                :href="getUpsellAlertHref()"
              >
                <!-- ONLY FOR DEDICATED -->
              </ciam-alert>

              <ciam-alert
                v-else-if="problem.status === 403"
                :title="problem.title"
                :description="problem.detail"
                :type="AlertStatus.WARNING"
              ></ciam-alert>

              <ciam-alert
                v-else-if="problem.status"
                :title="problem.title"
                :description="problem.detail"
                :type="AlertStatus.ALERT"
              ></ciam-alert>
            </div>
          </template>

          <template v-slot:pending>
            <div>
              <slot name="loading"></slot>
              <ciam-loader></ciam-loader>
            </div>
          </template>

          <template v-slot:default>
            <div>
              <!-- configuration panel content goes here -->

              <slot></slot>

              <Promised :promise="savingPromise">
                <template v-slot:rejected="problem">
                  <ciam-card-content-line>
                    <template v-slot:label></template>
                    <template v-slot:content>
                      <ciam-alert
                        :title="problem.title"
                        :description="problem.detail"
                        :type="AlertStatus.ALERT"
                      ></ciam-alert>
                    </template>
                  </ciam-card-content-line>
                </template>
                <template v-slot:pending>
                  <ciam-loader class="py-4"></ciam-loader>
                </template>
                <template v-slot:default></template>
              </Promised>

              <ciam-card-footer>
                <template #default>
                  <ciam-button class="primary" v-if="deploymentStatus === 'RUNNING'" v-once @click="emitSave()"
                  >{{ saveButtonLabel }}
                  </ciam-button>
                  <ciam-button v-else-if="deploymentStatus === 'ERROR'" class="disabled error" :disabled="disabled"
                  >{{ $t('deployment.configuration.deploymentInError') }}
                  </ciam-button>
                  <ciam-button v-else class="disabled animate-pulse" :disabled="disabled"
                  >{{ $t('deployment.configuration.deploymentBeingUpdated') }}
                  </ciam-button>
                </template>
              </ciam-card-footer>
            </div>
          </template>
        </Promised>
      </ciam-card-content-lines>
    </ciam-card-content>
  </ciam-card>
</template>

<script>
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import i18n from '@/i18n';
import UpsellFeatureService from '@/pages/UpsellFeatureService';

export default {
  name: 'ConfigurationPanel',
  components: { CiamAlert },
  data() {
    return {
      AlertStatus: AlertStatus,
      disabled: true,
    };
  },
  props: {
    deploymentStatus: {
      required: true,
      type: String,
    },
    header: {
      required: true,
      type: String,
    },
    anchor: {
      required: true,
      type: String
    },
    promise: {
      required: true,
      type: Promise,
    },
    saveButtonLabel: {
      type: String,
      default: i18n.t('deployment.configuration.save-and-redeploy'),
    },
    savingPromise: {
      required: true,
      type: Promise,
    },
    showUpgradePlanNotice: {
      type: Boolean,
      default: false,
    },
    featureName: {
      type: String,
      required: false,
    },
  },
  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(this.featureName);
    },

    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(this.featureName);
    },
    emitSave() {
      this.$emit('save');
    },
  },
  computed: {},
};
</script>

<style scoped>
.upgrade-plan {
  background-color: rgba(255, 99, 71, 0.2);
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 35px,
    rgba(255, 255, 255, 0.5) 35px,
    rgba(255, 255, 255, 0.5) 70px
  );
}
</style>

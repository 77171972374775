<template>
  <div>
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header><a :href="anchor">
          <ciam-icon class="text-gray-400 h-4 w-4 cursor-pointer" name="fa-link"></ciam-icon>
        </a>{{ $t('deployment.configuration.thirdParties.title') }}</template>
        <template v-slot:subtitle>{{ $t('deployment.configuration.thirdParties.subtitle') }}</template>
      </ciam-card-header>
      <ciam-card-content v-if="showThirdParties">
        <ciam-card-content-lines>
          <third-party type="TrustedCertificates" :deployment="deployment" />
          <third-party type="PasswordDenylist" :deployment="deployment" />
          <third-party type="mTLS" :notAvailable="true" :deployment="deployment" />
        </ciam-card-content-lines>
      </ciam-card-content>
      <div v-else>
        <ciam-alert
          title="Third parties feature unavailable"
          :description="getUpsellAlertDescription()"
          :type="AlertStatus.WARNING_HREF"
          :href-text="$t('upsell.email.contact')"
          :href="getUpsellAlertHref()"
        />
      </div>
    </ciam-card>
  </div>
</template>

<script>
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCardContent from '@/components/CiamCardContent';
import CiamCardContentLines from '@/components/CiamCardContentLines';
import ThirdParty from './ThirdParty.vue';
import PlanService from '@/pages/PlanService';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import UpsellFeatureService from '@/pages/UpsellFeatureService';

export default {
  name: 'ThirdParties',
  components: {
    CiamCard,
    CiamCardHeader,
    CiamCardContentLines,
    CiamAlert,
    ThirdParty,
  },
  props: {
    deployment: {
      type: Object,
      required: true,
    },
    anchor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      featureName: 'Third parties',
      AlertStatus: AlertStatus,
      upgradePlan: false,
      problem: null,
    };
  },
  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(this.featureName);
    },
    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(this.featureName);
    },
  },

  computed: {
    showThirdParties() {
      return PlanService.hasThirdParties(this.deployment);
    },
  },
  mounted() {},
};
</script>

<style scoped></style>

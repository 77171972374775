<template>
  <ciam-card-content-line>
    <template v-slot:label> {{ $t(`deployment.configuration.thirdParties.types.${type}.name`) }} </template>

    <template v-slot:content>

      <ciam-alert v-if="problem != null" :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
      <Promised :promise="thirdPartiesPromise">
        <template v-slot:pending>
          <ciam-loader class="py-4"></ciam-loader>
        </template>
        <template v-slot="data">
          <div class="flex flex-col gap-2">
            <ciam-help-text class="max-w-2xl text-sm text-gray-500" v-once
              >{{ $t(`deployment.configuration.thirdParties.types.${type}.description`) }}
              <ciam-link
                target="_blank"
                :href="$t(`deployment.configuration.thirdParties.types.${type}.documentationUrl`)"
                >Learn more
              </ciam-link></ciam-help-text
            >
            <div class="flex flex-col gap-2" v-if="notAvailable == false">
              <div class="flex flex-col gap-2 border-2 p-5 rounded-lg border-dashed items-center">
                <ciam-button
                  v-if="deployment.status === 'RUNNING'"
                  class="secondary w-max"
                  @click="upload()"
                  :loading="uploading"
                >
                  {{ $t(`deployment.configuration.thirdParties.types.${type}.ctaUpload`) }}
                  <template v-slot:right>
                    <ciam-icon name="fa-upload"></ciam-icon>
                  </template>
                </ciam-button>
                <ciam-button v-else class="disabled animate-pulse" :disabled="disabled"
                  >{{ $t('deployment.configuration.deploymentBeingUpdated') }}
                </ciam-button>
              </div>

              <ciam-list v-if="data.length > 0">
                <ciam-list-item v-for="(file, key) in data" :key="key">
                  <template v-slot:left>
                    <ciam-icon name="fa-file"></ciam-icon>
                    <span class="ml-2 flex-1 w-0 truncate">
                      <ciam-text class="medium">{{ file.name }}</ciam-text>
                      {{ file.updatedAt | formatDate }}
                    </span>
                  </template>
                  <template v-slot:right>
                    <div v-if="deployment.status === 'RUNNING'">
                      <ciam-link @click="downloadThirdParty(file)">Download</ciam-link>
                      <ciam-link
                        class="ml-2"
                        @click="
                          upload(
                            Maybe.Just(file),
                            $t(`deployment.configuration.thirdParties.types.${type}.apiEndpoint`)
                          )
                        "
                      >
                        {{ $t(`deployment.configuration.thirdParties.replace`) }}
                      </ciam-link>
                      <ciam-link class="danger ml-2" @click="openRemovalConfirmationModal(file)">
                        {{ $t(`deployment.configuration.thirdParties.remove`) }}
                      </ciam-link>
                    </div>
                  </template>
                </ciam-list-item>
              </ciam-list>
            </div>
            <div v-else>
              <ciam-alert
                :title="`${type} functionality in development`"
                description="Are you interested by this feature? Contact us at "
                :type="AlertStatus.WARNING_HREF"
                :href-text="$t('upsell.email.contact')"
                :href="getUpsellAlertHref()"
              />
            </div>

            <div class="fixed z-10 inset-0 overflow-y-auto" v-if="thirdPartyRemovalConfirmationModal.isOpened">
              <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                  <div
                    class="absolute inset-0 bg-gray-500 opacity-75"
                    @click="toggleThirdPartyRemovalConfirmationDialog()"
                  ></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div
                  class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      @click="toggleThirdPartyRemovalConfirmationDialog()"
                      type="button"
                      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span class="sr-only">Close</span>
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div
                      class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                      <svg
                        class="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                        {{ $t('deployment.configuration.thirdParties.removalConfirmationDialog.title') }}
                      </h3>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          {{
                            $t('deployment.configuration.thirdParties.removalConfirmationDialog.text1', {
                              filename: thirdPartyRemovalConfirmationModal.thirdParty.name,
                            })
                          }}
                          <br />
                          {{ $t('deployment.configuration.thirdParties.removalConfirmationDialog.text2') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      @click="removeThirdParty(thirdPartyRemovalConfirmationModal.thirdParty)"
                      type="button"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      {{ $t('deployment.configuration.thirdParties.removalConfirmationDialog.remove') }}
                    </button>
                    <button
                      @click="toggleThirdPartyRemovalConfirmationDialog()"
                      type="button"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    >
                      {{ $t('deployment.configuration.thirdParties.removalConfirmationDialog.cancel') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Promised>
    </template>
  </ciam-card-content-line>
</template>

<script>
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamIcon from '@/components/CiamIcon';
import CiamHelpText from '@/components/CiamHelpText';
import CiamLink from '@/components/CiamLink';
import CiamList from '@/components/CiamList';
import CiamText from '@/components/CiamText';
import CiamListItem from '@/components/CiamListItem';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import i18n from '@/i18n';
import { Promised } from 'vue-promised';
import Maybe from 'data.maybe-fgribreau';
import ThirdPartiesService from './ThirdPartiesService';
import { Notification } from 'vue-notifyjs';
import UpsellFeatureService from '@/pages/UpsellFeatureService';
import { type } from 'ramda';

export default {
  name: 'third-party',
  props: {
    type: {
      type: String,
      required: true,
    },
    deployment: {
      type: Object,
      required: true,
    },
    notAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Promised,
    CiamText,
    CiamIcon,
    CiamLink,
    CiamList,
    CiamListItem,
    CiamHelpText,
    CiamAlert,
  },
  data() {
    return {
      AlertStatus: AlertStatus,
      Maybe: Maybe,
      thirdPartiesPromise: new Promise(() => {
        // always loading promise
      }),
      problem: null,
      uploading: false,
      thirdPartyRemovalConfirmationModal: {
        isOpened: false,
        thirdParty: {},
      },
    };
  },
  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(this.type);
    },
    /**
     * Upload or re-upload an thirdParty
     * @param {Maybe<ThirdParty>} thirdParty — specified (Maybe.Just(thirdParty)) if this is a replace operation. Maybe.Nothing if this is a simple upload
     */
    upload(thirdParty = Maybe.Nothing()) {
      const maxSizeInBytes = this.$t(`deployment.configuration.thirdParties.types.${this.type}.upload.maxSizeInBytes`);
      const ipt = document.createElement('input');
      ipt.type = 'file';
      ipt.multiple = false;
      ipt.style.display = 'none';
      ipt.accept = this.$t(`deployment.configuration.thirdParties.types.${this.type}.upload.accept`);
      ipt.size;
      document.body.appendChild(ipt);
      const allowedExtensions = this.$t(
        `deployment.configuration.thirdParties.types.${this.type}.upload.extensionsAllowed`
      );

      ipt.click();
      ipt.onchange =  async () => {
        // Check allowed extensions
        const fileExtension = ipt.files[0].name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes('.' + fileExtension)) {
          Notification.notify({
            message: i18n.t(
              this.$t(`deployment.configuration.thirdParties.types.${this.type}.upload.warningExtensionMsg`)
            ),
            type: 'warning',
          });
          return;
        }

        if (ipt.files.length !== 1) {
          return;
        }

        if (
          thirdParty.isNothing &&
          !confirm(i18n.t('deployment.configuration.thirdParties.upload_confirm', { name: ipt.files[0].name }))
        ) {
          // this is a upload
          return;
        }
        
        const thirdParties = await ThirdPartiesService.listAll(this.deployment.id)
        .then(tp => tp.map(thirdParty => thirdParty.name))
        .catch((problem) => this.problem = problem);
        if(
          thirdParty.isJust && 
          ipt.files[0].name != thirdParty.value.name && 
          thirdParties.includes(ipt.files[0].name)
        ) {
          alert(i18n.t('deployment.configuration.thirdParties.already_exists'))
          return;
        }

        if (
          thirdParty.isJust &&
          !confirm(i18n.t('deployment.configuration.thirdParties.replace_confirm', { name: ipt.files[0].name }))
        ) {
          // this is a re-upload
          return;
        }

        this.uploading = true;
        let loader = this.showLoader();
        ThirdPartiesService.create(
          this.deployment.id,
          ipt.files[0],
          thirdParty,
          this.$t(`deployment.configuration.thirdParties.types.${this.type}.apiEndpoint`)
        )
          .then(() => {
            Notification.notify({
              message: i18n.t('deployment.configuration.thirdParties.uploaded', { filename: ipt.files[0].name }),
              type: 'info',
            });
          })
          .catch((problem) => this.problem = problem)
          .finally(() => {
            this.uploading = false;
            loader.hide();
            this.refreshList();
          });
      };
    },
    showLoader() {
      let _loader = this.$loading.show();
      return {
        hide() {
          _loader.hide();
        },
      };
    },
    downloadThirdParty(thirdParty) {
      ThirdPartiesService.downloadFile(this.deployment.id, thirdParty.type, thirdParty.fileId)
        .then((response) => {
          // Do something with the file Blob in the response.
          // For example, you can create a download link.
          const blob = response.data;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = thirdParty.name; // Set the desired file name.
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.problem = error
          // Handle any errors that occur during the download.
          console.error('Error downloading file:', error);
        });
    },
    removeThirdParty(thirdParty) {
      this.toggleThirdPartyRemovalConfirmationDialog();
      let loader = this.showLoader();
      ThirdPartiesService.remove(this.deployment.id, thirdParty.fileId, thirdParty.type)
        .then(() => {
          Notification.notify({
            message: i18n.t('deployment.configuration.thirdParties.removed'),
            type: 'info',
          });
        })
        .catch((problem) => this.problem = problem)
        .finally(() => {
          loader.hide();
          this.refreshList();
        });
    },
    toggleThirdPartyRemovalConfirmationDialog() {
      this.thirdPartyRemovalConfirmationModal.isOpened = !this.thirdPartyRemovalConfirmationModal.isOpened;
    },
    openRemovalConfirmationModal(thirdParty) {
      this.toggleThirdPartyRemovalConfirmationDialog();
      this.thirdPartyRemovalConfirmationModal.thirdParty = thirdParty;
    },
    refreshList() {
      // Get list of filtered third parties (ex: trusted-certificates)
      ThirdPartiesService.listByType(
        this.deployment.id,
        this.$t(`deployment.configuration.thirdParties.types.${this.type}.apiEndpoint`)
      ).then(
        (thirdPartiesPromise) => (this.thirdPartiesPromise = Promise.resolve(thirdPartiesPromise)),
        (err) => (this.thirdPartiesPromise = Promise.reject(err))
      )
      .catch((problem) => this.problem = problem);
    },
  },
  mounted() {
    this.refreshList();
  },
  computed: {
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
    /**
     * @return {boolean} true if the user is an organization owner
     */
  },
};
</script>

<template>
  <ciam-card :bg="'gray'">
    <ciam-card-header>
      <template v-slot:subtitle>
        <ciam-text>{{ $t('deployment.configuration.header') }}</ciam-text>
      </template>
      <template v-slot:actions>
        <ciam-button v-if="!deployment.pricingPlan.free" :disabled="deployment.status !== 'RUNNING'" class="primary" @click="redeploy()">Restart Keycloak</ciam-button>
        <ciam-button
          class="secondary"
          target-url="https://documentation.cloud-iam.com/deployment/environment-variables.html"
        >
          {{ $t('deployment.configuration.help') }}
          <template v-slot:right>
            <ciam-icon name="fa-book"></ciam-icon>
          </template>
        </ciam-button>
      </template>
    </ciam-card-header>

    <Promised :promise="loading">
      <template v-slot:pending>
        <ciam-loader class="py-5"></ciam-loader>
      </template>

      <template v-slot:rejected="error">
        <ciam-alert :title="error.message" :type="AlertStatus.ALERT"></ciam-alert>
      </template>

      <template v-slot:default>
        <div
          v-if="deployment.status === 'RUNNING' || deployment.status === 'STARTING' || deployment.status === 'ERROR'"
        >
          <ciam-card-content-lines>
            <ciam-card-content-line
              v-if="deployment.status === 'ERROR'"
              type="full-width"
              class="bg-white flex flex-col gap-2 items-center"
            >
              <template v-slot:content>
                <div class="is-error is-light bg-red-100 p-2 rounded-md text-red-800">
                  {{ $t('deployment.configuration.defaultError') }}
                </div>
              </template>
            </ciam-card-content-line>
            <ciam-card-content-line id="environment-variables" type="full-width">
              <template v-slot:content>
                <environment-variables :deployment="deployment"></environment-variables>
              </template>
            </ciam-card-content-line>

            <ciam-card-content-line id="allowlist" type="full-width">
              <template v-slot:content>
                <allowlist :deployment="deployment"></allowlist>
              </template>
            </ciam-card-content-line>

            <ciam-card-content-line id="keycloak-flags" type="full-width">
              <template v-slot:content>
                <keycloak-flags :deployment="deployment"></keycloak-flags>
              </template>
            </ciam-card-content-line>
          </ciam-card-content-lines>

          <ciam-card-content-line id="custom-domain" type="full-width">
            <template v-slot:content>
              <custom-domain :deployment="deployment"></custom-domain>
            </template>
          </ciam-card-content-line>

          <ciam-card-content-line id="maintenance-window" type="full-width">
            <template v-slot:content>
              <maintenance-window v-if="deployment" :deployment="deployment" />
            </template>
          </ciam-card-content-line>

          <ciam-card-content-line id="third-parties" type="full-width">
            <template v-slot:content>
              <third-parties anchor="#third-parties" :deployment="deployment" />
            </template>
          </ciam-card-content-line>
        </div>
      </template>
    </Promised>
  </ciam-card>
</template>

<script>
import EnvironmentVariables from '@/pages/deployments/configurations/environment-variables/EnvironmentVariables';
import Allowlist from '@/pages/deployments/configurations/allowlist/Allowlist';
import DeploymentService from '@/pages/deployments/DeploymentService';
import KeycloakFlags from '@/pages/deployments/configurations/keycloak-flag/KeycloakFlags';
import CustomDomain from '@/pages/deployments/configurations/custom-domain/CustomDomain';
import ThirdParties from './third-parties/ThirdParties.vue';
import MaintenanceWindow from '@/pages/deployments/configurations/maintenance-window/MaintenanceWindow.vue';

export default {
  name: 'DeploymentConfigurations',
  components: {
    CustomDomain,
    KeycloakFlags,
    Allowlist,
    EnvironmentVariables,
    ThirdParties,
    MaintenanceWindow,
  },
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: Promise.resolve(),
      organization: null,
    };
  },
  mounted() {
    this.scrollToAnchor();
  },
  methods: {
    redeploy() {
      if (!confirm('Are you sure you want to restart your Keycloak cluster ?')) {
        return;
      }
      DeploymentService.redeploy(this.$route.params.id, "keycloak-essentials").then(
        () => {
          Notification.notify({
            message: 'Restart of your Keycloak cluster has started...',
            type: 'info',
          });
        },
        (err) => {
          console.error(err);
          Notification.notify({
            message: 'Could not redeploy:' + err,
            type: 'danger',
          });
        },
      );
    },
    scrollToAnchor() {
      const hash = window.location.hash;

      if (hash) {
        this.$nextTick(() => {
          setTimeout(() => {
            const element = document.querySelector(hash);
            if (element) {
              const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

              window.scrollTo({
                top: elementPosition,
                behavior: 'smooth',
              });
            }
          }, 200); // Adjust the delay as needed
        });
      }
    },
  },
  computed: {
    deploymentId() {
      return this.deployment.id;
    },
  },
};
</script>


<template>
  <div>
    <div v-if="form.values.length > 1" class="priority-helper">
      {{ $t('deployment.configuration.allowlist.firstRule') }}
    </div>
    <div class="kv-item" v-for="(item, index) in form.values" :key="index">
      <ciam-button @click="up(index)" :disabled="index === 0" class="white col-span-1">
        <ciam-icon name="fa-chevron-up"></ciam-icon>
      </ciam-button>
      <ciam-button @click="down(index)" :disabled="index === form.values.length - 1" class="white col-span-1">
        <ciam-icon name="fa-chevron-down"></ciam-icon>
      </ciam-button>
      <ciam-select :options="directives" :disabled="disabled" v-model="item.directive" class="col-span-2" />
      <ciam-input
        class="col-span-3"
        type="text"
        v-model="item.source"
        :disabled="disabled"
        :placeholder="$t('deployment.configuration.allowlist.entry.source')"
      />
      <ciam-input
        class="col-span-3"
        type="text"
        v-model="item.name"
        :disabled="disabled"
        :placeholder="$t('deployment.configuration.allowlist.entry.name')"
      />

      <ciam-button @click="remove(index)" :disabled="form.values.length <= 1 || disabled" class="white col-span-1">
        <ciam-icon name="fa-minus"></ciam-icon>
      </ciam-button>
      <ciam-button @click="add(index)" :disabled="disabled" class="white col-span-1">
        <ciam-icon name="fa-plus"></ciam-icon>
      </ciam-button>
    </div>
    <div v-if="form.values.length > 1" class="priority-helper">
      {{ $t('deployment.configuration.allowlist.lastRule') }}
    </div>
  </div>
</template>

<script>
import CiamInput from '@/components/CiamInput';
import CiamButton from '@/components/CiamButton';
import CiamIcon from '@/components/CiamIcon';
import i18n from '@/i18n';
import CiamSelect from '@/components/CiamSelect';

export default {
  name: 'AllowlistEditor',
  props: {
    values: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CiamSelect, CiamIcon, CiamButton, CiamInput },
  data() {
    //  always start with at least one element
    return {
      directives: [
        {
          label: i18n.t('allowlist.directives.allow'),
          value: 'allow',
        },
        {
          label: i18n.t('allowlist.directives.deny'),
          value: 'deny',
        },
      ],
      form: {
        values: this.values.length === 0 ? [this.generator()] : this.values,
      },
    };
  },
  methods: {
    remove(index) {
      this.form.values.splice(index, 1);
    },
    add(index) {
      this.form.values.splice(index + 1, 0, this.generator());
    },
    up(index) {
      this.moveItem(index, index - 1);
    },
    down(index) {
      this.moveItem(index, index + 1);
    },
    moveItem(from, to) {
      const element = this.form.values[from];
      this.form.values.splice(from, 1);
      this.form.values.splice(to, 0, element);
    },
    generator() {
      return {
        name: '',
        directive: 'deny',
        target: '',
      };
    },
  },
  mounted() {
    this.$emit('input', this.data);
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.kv-item {
  @apply grid grid-cols-12 gap-1 mb-4;
}

.priority-helper {
  @apply gap-4 mb-4;
  text-align: right;
  font-style: italic;
}
</style>

<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input
        v-bind="{ ...$props, ...$attrs }"
        :id="checkbox_id"
        class="ciam-checkbox"
        name="comments"
        type="checkbox"
        :checked="$attrs.value ? 'checked' : ''"
        @input="_emit($event.target.checked)"
      />
    </div>
    <div class="ml-3 text-sm" v-if="label || description">
      <label :for="checkbox_id" class="ciam-label" v-if="label" v-text="label"></label>
      <p class="text-gray-500" v-text="description" v-if="description"></p>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';

/**
 * Usage:

 <ciam-checkbox v-model="checked" label="my label" description="my checkbox description"></ciam-checkbox>

 */
export default {
  name: 'ciam-checkbox',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      checkbox_id: `id_` + uuid.v4(),
    };
  },
  methods: {
    _emit(value) {
      this.$emit('input', value);
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.ciam-checkbox {
  @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded;
}

.ciam-label {
  @apply font-medium text-gray-700;
}
</style>

<template>
  <configuration-panel
    :header="$t('deployment.configuration.customDomain.title')"
    anchor="#custom-domain"
    :promise="promise"
    :saving-promise="savingPromise"
    :show-upgrade-plan-notice="upgradePlan"
    :deploymentStatus="this.deployment.status"
    @save="save()"
    :saveButtonLabel="$t('deployment.configuration.customDomain.save')"
    :featureName="$t('deployment.configuration.customDomain.featureName')"
  >
    <template v-slot:subtitle>
      <ciam-text>{{ $t('deployment.configuration.customDomain.subtitle') }}</ciam-text>
    </template>

    <template>
      <ciam-card-content-line type="split" v-if="form.customDomains.length > 0">
        <template v-slot:label>
          <ciam-text>{{ $t('deployment.configuration.customDomain.domain') }}</ciam-text>
        </template>
        <template v-slot:content>
          <div v-for="customDomain in form.customDomains" v-bind:key="customDomain.domain">
            <ciam-text v-if="customDomain.proxied === true">
              <ciam-icon
                name="fa-arrow-turn-up"
                class="fa-rotate-by fa-lg text-black"
                style="--fa-rotate-angle: 38deg"
              ></ciam-icon>
              <i>{{ $t('deployment.configuration.customDomain.proxied') }}</i>
            </ciam-text>
            <ciam-text v-else-if="customDomain.automatic === true">
              <ciam-icon name="fa-shield" class="text-blue-800"></ciam-icon>
              <i>{{ $t('deployment.configuration.customDomain.automatic') }}</i>
            </ciam-text>
            <ciam-text v-else>
              <ciam-icon name="fa-shield"></ciam-icon>
              <i>{{ $t('deployment.configuration.customDomain.manual', { expireAt: toLocalDate(customDomain.expireAt) }) }}</i>
            </ciam-text>
            <div class="custom-domain">
              <ciam-input
                :value="customDomain.domain"
                type="text"
                :disabled="true"
                class="col-span-11"
              />
              <ciam-button
                :disabled="deployment.status !== 'RUNNING'"
                class="white col-span-1"
                @click="unregister(customDomain.domain)"
              >
                <ciam-icon name="fa-trash"></ciam-icon>
              </ciam-button>
            </div>
          </div>
        </template>
      </ciam-card-content-line>
      <ciam-card-content-line type="split-content-component">
        <template v-slot:label>
          <ciam-text>{{ $t('deployment.configuration.customDomain.attach') }}</ciam-text>
        </template>
        <template v-slot:content>
          <div class="how-to">
            <ciam-text>{{ $t('deployment.configuration.customDomain.howTo') }}</ciam-text>
            <ciam-clipboard title="Copy CNAME">
              <span class="domain">{{ deployment.name }}.cloud-iam.com.</span>
            </ciam-clipboard>
          </div>
          <ciam-input :disabled="deployment.status !== 'RUNNING'" v-model="form.register.domain" type="text" />
          <ciam-checkbox
            style="padding-top: 0.5rem"
            :disabled="deployment.status !== 'RUNNING'"
            v-model="form.register.proxied"
            label="Requests are proxied to Cloud-IAM through this domain"
          ></ciam-checkbox>
        </template>
      </ciam-card-content-line>
    </template>
  </configuration-panel>
</template>

<script>
import ConfigurationPanel from '@/pages/deployments/configurations/ConfigurationPanel';
import { Notification } from 'vue-notifyjs';
import i18n from '@/i18n';
import CiamInput from '@/components/CiamInput';
import CustomDomainService from '@/pages/deployments/configurations/custom-domain/CustomDomainService';
import CiamClipboard from '@/components/CiamClipboard.vue';
import Vue from 'vue';
import CiamCheckbox from '@/components/themes_templates/CiamCheckbox.vue';

export default {
  name: 'CustomDomain',
  components: {
    CiamCheckbox,
    CiamClipboard,
    ConfigurationPanel,
    CiamInput,
  },
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      promise: Promise.resolve(),
      savingPromise: Promise.resolve(),
      form: {
        customDomains: [],
        register: {
          domain: '',
          proxied: false,
        },
      },
      upgradePlan: false,
      problem: null,
    };
  },
  methods: {
    toLocalDate(d) {
      return Vue.filter('adaptivelyFormatDate')(d);
    },
    unregister(domain) {
      if (!confirm(i18n.t('deployment.configuration.customDomain.unregister', { domain: domain }))) {
        return;
      }
      CustomDomainService.unregister(this.deployment.id, domain).finally(() => {
        this.promise = CustomDomainService.getCustomDomains(this.deployment.id).then(
          (data) => (this.form.customDomains = data)
        );
      });
    },
    save() {
      if (this.form.register.domain !== '') {
        this.savingPromise = CustomDomainService.register(
          this.deployment.id,
          this.form.register.domain,
          this.form.register.proxied
        ).then(() =>
          Notification.notify({
            message: i18n.t('deployment.configuration.notification'),
            type: 'info',
          })
        );
      } else {
        Notification.notify({
          message: i18n.t('deployment.configuration.customDomain.emptyAttachDomain'),
          type: 'warning',
        });
      }
    },
  },
  mounted() {
    this.promise = CustomDomainService.getCustomDomains(this.deployment.id).then(
      (data) => (this.form.customDomains = data)
    );
  },
};
</script>

<style lang="scss" scoped>
.custom-domain {
  @apply grid grid-cols-12 gap-1 mb-4;
}

.how-to {
  margin-bottom: 1rem;
}

.domain {
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #eeeeee;
  padding: 0.2rem;
  margin: 0.2rem;
  font-family: monospace;
  white-space: nowrap;
}
</style>

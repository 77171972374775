<template>
  <div style="width: 100%">
    <div class="flex flex-row w-full justify-end mb-4">
      <ciam-button @click="add()" :disabled="disabled" class="white col-span-1">
        Add
        <ciam-icon class="ml-2" name="fa-plus"></ciam-icon>
      </ciam-button>
    </div>
    <div class="kv-item" v-for="(item, index) in data" :key="index">
      <ciam-input type="text" v-model="item.name" class="col-span-4" :disabled="disabled" :placeholder="keyPlaceholder">
        <template v-if="item.warning" v-slot:warningText>
          {{ item.warning }}
          <div v-if="item.documentation">
            <a :href="item.documentation" target="_blank"><u>Check our documentation</u></a>
          </div>
        </template>
      </ciam-input>
      <ciam-input
        v-if="item.type === 'clear'"
        type="text"
        v-model="item.value"
        class="col-span-4"
        :disabled="disabled || item.name.length === 0"
        :placeholder="valuePlaceholder"
      />
      <ciam-input
        type="text"
        v-if="item.type === 'secret'"
        v-model="item.value"
        class="col-span-4"
        :disabled="disabled || item.name.length === 0"
        placeholder="*******"
      />
      <div class="flex flex-row gap-4 col-span-1">
        <b-tooltip v-if="item.type !== 'secret'" :label="$t('deployment.configuration.env.tooltip.mask')" type="is-info"
                   position="is-top">
          <ciam-button @click="openHideSecretModal(index)" :disabled="disabled" class="h-8 w-8">
            <ciam-icon name="fa-lock" />
          </ciam-button>
        </b-tooltip>
        <b-tooltip v-else :label="$t('deployment.configuration.env.tooltip.clear')" type="is-info" position="is-top">
          <ciam-button @click="openClearSecretModal(index)" :disabled="disabled" class="h-8 w-8">
            <ciam-icon name="fa-unlock-alt" />
          </ciam-button>
        </b-tooltip>
        <b-tooltip :label="$t('deployment.configuration.env.tooltip.delete')" type="is-info" position="is-top">
          <ciam-button @click="remove(index)" :disabled="disabled" class="h-8 w-8">
            <ciam-icon class="text-red-500" name="fa-trash" />
          </ciam-button>
        </b-tooltip>
      </div>
    </div>
    <b-modal
      :active.sync="maskSecretModal.isOpened"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <standard-modal
        :buttons="maskSecretModal.buttons"
        @result="maskSecret"
      >
        <template v-slot:header>{{ $t('deployment.configuration.env.maskSecretModal.header') }}</template>
        <template v-slot:default>
          <div>
            <ciam-text>{{ $t('deployment.configuration.env.maskSecretModal.description') }}</ciam-text>
          </div>
        </template>
      </standard-modal>
    </b-modal>
    <b-modal
      :active.sync="clearSecretModal.isOpened"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <standard-modal
        :buttons="clearSecretModal.buttons"
        @result="clearSecret"
      >
        <template v-slot:header>{{ $t('deployment.configuration.env.clearSecretModal.header') }}</template>
        <template v-slot:default>
          <div>
            <ciam-text>{{ $t('deployment.configuration.env.clearSecretModal.description') }}</ciam-text>
          </div>
        </template>
      </standard-modal>
    </b-modal>
  </div>
</template>

<script>
import CiamInput from '@/components/CiamInput';
import CiamButton from '@/components/CiamButton';
import CiamIcon from '@/components/CiamIcon';
import StandardModal from '@/components/StandardModal.vue';

function getDefaultItem() {
  return { name: '', value: '', type: 'clear' };
}

export default {
  name: 'ciam-key-value',
  props: {
    /**
     * note that this value will be mutated
     */
    value: {
      type: Array,
      required: true,
      validator: (val) => {
        // eslint-disable-next-line no-prototype-builtins
        return val.every((item) => item.hasOwnProperty('name') && item.hasOwnProperty('value') && item.hasOwnProperty('type'));
      },
    },
    keyPlaceholder: {
      type: String,
      required: false,
      default: '',
    },
    valuePlaceholder: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { StandardModal, CiamIcon, CiamButton, CiamInput },
  data() {
    //  always start with at least one element
    const data = this.value.length === 0 ? [getDefaultItem()] : this.value;
    return {
      data,
      currentItemIndex: null,
      maskSecretModal: {
        isOpened: false,
        buttons: [
          { text: this.$t('deployment.configuration.env.maskSecretModal.cancel') },
          {
            text: this.$t('deployment.configuration.env.maskSecretModal.action'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
      clearSecretModal: {
        isOpened: false,
        buttons: [
          { text: this.$t('deployment.configuration.env.clearSecretModal.cancel') },
          {
            text: this.$t('deployment.configuration.env.clearSecretModal.action'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
    };
  },
  methods: {
    remove(index) {
      this.data.splice(index, 1);
      this.$emit('input', this.data);
    },
    add() {
      this.data.push(getDefaultItem());
      this.$emit('input', this.data);
    },
    openHideSecretModal(index) {
      this.currentItemIndex = index;
      this.maskSecretModal.isOpened = true;
    },
    openClearSecretModal(index) {
      this.currentItemIndex = index;
      this.clearSecretModal.isOpened = true;
    },
    maskSecret(result) {
      if (result) {
        this.data[this.currentItemIndex].type = 'secret';
        this.$emit('input', this.data);
      }
      this.maskSecretModal.isOpened = false;
    },
    clearSecret(result) {
      if (result) {
        this.data[this.currentItemIndex].type = 'clear';
        this.data[this.currentItemIndex].value = '';
        this.$emit('input', this.data);
      }
      this.clearSecretModal.isOpened = false;
    },
  },
  mounted() {
    this.$emit('input', this.data);
  },
};
</script>

<style lang="scss" scoped>
.kv-item {
  @apply grid grid-cols-9 gap-4 mb-4 items-center justify-center align-middle;
}
</style>

<template>
  <ciam-card>
    <ciam-card-header>
      <template v-slot:header>
        <a href="#maintenance-window">
          <ciam-icon class="text-gray-400 h-4 w-4 cursor-pointer" name="fa-link"></ciam-icon>
        </a>
        {{ $t('deployment.configuration.maintenanceWindow.title') }}
      </template>
      <template v-slot:subtitle>
        <ciam-text>{{ $t('deployment.configuration.maintenanceWindow.subtitle') }}</ciam-text>
      </template>
    </ciam-card-header>

    <Promised :promise="loading">
      <template v-slot:pending>
        <slot name="loading"></slot>
      </template>

      <template v-slot:rejected="problem">
        <ciam-alert
          v-if="(problem.type = 'not-available-on-current-plan')"
          :title="problem.title"
          :description="getUpsellAlertDescription()"
          :type="AlertStatus.WARNING_HREF"
          :href-text="$t('upsell.email.contact')"
          :href="getUpsellAlertHref()"
        />
      </template>

      <ciam-card-content-line type="split-content-component">
        <template v-slot:content>
          <div class="flex flex-row gap-2 justify-stretch">
            <div class="mt-2">
              <ciam-text>Time</ciam-text>
            </div>
            <div>
              <ciam-select
                :options="[
                { label: '00', value: '00' },
                { label: '01', value: '01' },
                { label: '02', value: '02' },
                { label: '03', value: '03' },
                { label: '04', value: '04' },
                { label: '05', value: '05' },
                { label: '06', value: '06' },
                { label: '07', value: '07' },
                { label: '08', value: '08' },
                { label: '09', value: '09' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
            ]"
                v-model="hours"
              ></ciam-select>
            </div>
            <div class="mt-2">:</div>
            <div>
              <ciam-select
                :options="[
                { label: '00', value: '00' },
                { label: '15', value: '15' },
                { label: '30', value: '30' },
                { label: '45', value: '45' },
            ]"
                v-model="minutes"
              ></ciam-select>
            </div>

            <div class="mt-2">UTC</div>
            <div>
              <ciam-select
                :options="[
                { label: '-11', value: '-11' },
                { label: '-10', value: '-10' },
                { label: '-9', value: '-09' },
                { label: '-8', value: '-08' },
                { label: '-7', value: '-07' },
                { label: '-6', value: '-06' },
                { label: '-5', value: '-05' },
                { label: '-4', value: '-04' },
                { label: '-3', value: '-03' },
                { label: '-2', value: '-02' },
                { label: '-1', value: '-01' },
                { label: '0', value: '+00' },
                { label: '+1', value: '+01' },
                { label: '+2', value: '+02' },
                { label: '+3', value: '+03' },
                { label: '+4', value: '+04' },
                { label: '+5', value: '+05' },
                { label: '+6', value: '+06' },
                { label: '+7', value: '+07' },
                { label: '+8', value: '+08' },
                { label: '+9', value: '+09' },
                { label: '+10', value: '+10' },
                { label: '+11', value: '+11' },
                { label: '+12', value: '+12' },
            ]"
                v-model="utc"
              ></ciam-select>
            </div>
            <div class="mt-2 ml-12">
              <ciam-text>Duration</ciam-text>
            </div>
            <div>
              <ciam-select
                :options="[
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
                { label: '7', value: '7' },
                { label: '8', value: '8' },
                { label: '9', value: '9' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' },
                { label: '13', value: '13' },
                { label: '14', value: '14' },
                { label: '15', value: '15' },
                { label: '16', value: '16' },
                { label: '17', value: '17' },
                { label: '18', value: '18' },
                { label: '19', value: '19' },
                { label: '20', value: '20' },
                { label: '21', value: '21' },
                { label: '22', value: '22' },
                { label: '23', value: '23' },
                { label: '24', value: '24' },
            ]"
                v-model="duration"
              ></ciam-select>
            </div>
            <div class="mt-2">Hours</div>
            <div class="w-[500px]">
              <ciam-button class="primary" @click="setMaintenanceWindow()">Validate</ciam-button>
            </div>
          </div>
        </template>
      </ciam-card-content-line>
    </Promised>


  </ciam-card>
</template>
<script>

import CiamSelect from '@/components/CiamSelect.vue';
import CiamText from '@/components/CiamText.vue';
import DeploymentService from '@/pages/deployments/DeploymentService';
import { Notification } from 'vue-notifyjs';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import { makeQueryablePromise } from '@/util/promise';
import UpsellFeatureService from '@/pages/UpsellFeatureService';

export default {
  components: { CiamAlert, CiamSelect, CiamText },
  data() {
    return {
      AlertStatus: AlertStatus,
      promise: Promise.resolve(),
      savingPromise: Promise.resolve(),
      hours: null,
      minutes: null,
      duration: null,
      utc: null,
      loading: makeQueryablePromise(Promise.reject()),
      featureName: 'Maintenance window',
    };
  },
  props: {
    deployment: Object,
  },
  mounted() {
    this.loading = DeploymentService.getMaintenanceWindow(this.deployment.id).then(data => {
      this.hours = data.hours;
      this.minutes = data.minutes;
      this.duration = data.duration;
      this.utc = data.utc;
    });
  },
  methods: {
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(this.featureName);
    },
    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(this.featureName);
    },
    setMaintenanceWindow() {
      let loader = this.showLoader();
      const maintenance = {
        utc: this.utc,
        minutes: this.minutes,
        hours: this.hours,
        duration: this.duration,
      };
      try {
        DeploymentService.setMaintenanceWindow(this.deployment.id, maintenance);
      } catch (e) {
        this.$log.error('Error while updating the maintenance window', e);
      } finally {
        loader.hide();
        Notification.notify({
          type: 'info',
          message: this.$t(('notifications.maintenanceWindowUpdated')),
        });
      }

    },
    showLoader() {
      let _loader = this.$loading.show();
      return {
        hide() {
          _loader.hide();
        },
      };
    },
  },
};
</script>

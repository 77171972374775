<template>
  <configuration-panel
    :header="$t('deployment.configuration.keycloakFlags.title')"
    :promise="promise"
    :saving-promise="savingPromise"
    :show-upgrade-plan-notice="upgradePlan"
    :deploymentStatus="this.deployment.status"
    @save="save()"
    :featureName="$t('deployment.configuration.keycloakFlags.featureName')"
    anchor="#keycloak-flags">
    <template v-slot:subtitle>
      <ciam-text>{{ $t('deployment.configuration.keycloakFlags.subtitle') }}</ciam-text>
    </template>

    <template>
      <ciam-card-content-line type="split-content-component">
        <template v-slot:label>
          <ciam-text>{{ $t('deployment.configuration.keycloakFlags.profiles') }}</ciam-text>
        </template>
        <template v-slot:content>
          <ul>
            <li v-for="profile in form" v-bind:key="profile.keycloakFlagId">
              <ciam-three-states :disabled="deployment.status !== 'RUNNING'" v-model="profile.status" />
              <ciam-text>{{ profile.description }}</ciam-text>
            </li>
          </ul>
        </template>
      </ciam-card-content-line>
    </template>
  </configuration-panel>
</template>

<script>
import ConfigurationPanel from '@/pages/deployments/configurations/ConfigurationPanel';
import { Notification } from 'vue-notifyjs';
import i18n from '@/i18n';
import KeycloakFlagService, {
  SetKeycloakFlag,
} from '@/pages/deployments/configurations/keycloak-flag/KeycloakFlagService';
import CiamThreeStates from '@/components/CiamThreeStates';

export default {
  name: 'KeycloakFlags',
  components: {
    CiamThreeStates,
    ConfigurationPanel,
  },
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      promise: Promise.resolve(),
      savingPromise: Promise.resolve(),
      form: [],
      upgradePlan: false,
      problem: null,
    };
  },
  methods: {
    save() {
      const configured = this.form
        .filter((entry) => entry.status !== 'default')
        .map((entry) => new SetKeycloakFlag(entry.keycloakFlagId, entry.status));
      this.savingPromise = KeycloakFlagService.setKeycloakFlags(this.deployment.id, configured).then(() =>
        Notification.notify({
          message: i18n.t('deployment.configuration.notification'),
          type: 'info',
        }),
      );
    },
  },
  mounted() {
    this.promise = KeycloakFlagService.getKeycloakFlags(this.deployment.id).then((data) => {
      this.form = data;
    });
  },
};
</script>

<style scoped>
li {
  margin-bottom: 0.2rem;
}
</style>

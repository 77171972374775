var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ciam-card',[_c('ciam-card-header',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('a',{attrs:{"href":"#maintenance-window"}},[_c('ciam-icon',{staticClass:"text-gray-400 h-4 w-4 cursor-pointer",attrs:{"name":"fa-link"}})],1),_vm._v(" "+_vm._s(_vm.$t('deployment.configuration.maintenanceWindow.title'))+" ")]},proxy:true},{key:"subtitle",fn:function(){return [_c('ciam-text',[_vm._v(_vm._s(_vm.$t('deployment.configuration.maintenanceWindow.subtitle')))])]},proxy:true}])}),_c('Promised',{attrs:{"promise":_vm.loading},scopedSlots:_vm._u([{key:"pending",fn:function(){return [_vm._t("loading")]},proxy:true},{key:"rejected",fn:function(problem){return [((problem.type = 'not-available-on-current-plan'))?_c('ciam-alert',{attrs:{"title":problem.title,"description":_vm.getUpsellAlertDescription(),"type":_vm.AlertStatus.WARNING_HREF,"href-text":_vm.$t('upsell.email.contact'),"href":_vm.getUpsellAlertHref()}}):_vm._e()]}}],null,true)},[_c('ciam-card-content-line',{attrs:{"type":"split-content-component"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"flex flex-row gap-2 justify-stretch"},[_c('div',{staticClass:"mt-2"},[_c('ciam-text',[_vm._v("Time")])],1),_c('div',[_c('ciam-select',{attrs:{"options":[
              { label: '00', value: '00' },
              { label: '01', value: '01' },
              { label: '02', value: '02' },
              { label: '03', value: '03' },
              { label: '04', value: '04' },
              { label: '05', value: '05' },
              { label: '06', value: '06' },
              { label: '07', value: '07' },
              { label: '08', value: '08' },
              { label: '09', value: '09' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' },
              { label: '13', value: '13' },
              { label: '14', value: '14' },
              { label: '15', value: '15' },
              { label: '16', value: '16' },
              { label: '17', value: '17' },
              { label: '18', value: '18' },
              { label: '19', value: '19' },
              { label: '20', value: '20' },
              { label: '21', value: '21' },
              { label: '22', value: '22' },
              { label: '23', value: '23' } ]},model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}})],1),_c('div',{staticClass:"mt-2"},[_vm._v(":")]),_c('div',[_c('ciam-select',{attrs:{"options":[
              { label: '00', value: '00' },
              { label: '15', value: '15' },
              { label: '30', value: '30' },
              { label: '45', value: '45' } ]},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}})],1),_c('div',{staticClass:"mt-2"},[_vm._v("UTC")]),_c('div',[_c('ciam-select',{attrs:{"options":[
              { label: '-11', value: '-11' },
              { label: '-10', value: '-10' },
              { label: '-9', value: '-09' },
              { label: '-8', value: '-08' },
              { label: '-7', value: '-07' },
              { label: '-6', value: '-06' },
              { label: '-5', value: '-05' },
              { label: '-4', value: '-04' },
              { label: '-3', value: '-03' },
              { label: '-2', value: '-02' },
              { label: '-1', value: '-01' },
              { label: '0', value: '+00' },
              { label: '+1', value: '+01' },
              { label: '+2', value: '+02' },
              { label: '+3', value: '+03' },
              { label: '+4', value: '+04' },
              { label: '+5', value: '+05' },
              { label: '+6', value: '+06' },
              { label: '+7', value: '+07' },
              { label: '+8', value: '+08' },
              { label: '+9', value: '+09' },
              { label: '+10', value: '+10' },
              { label: '+11', value: '+11' },
              { label: '+12', value: '+12' } ]},model:{value:(_vm.utc),callback:function ($$v) {_vm.utc=$$v},expression:"utc"}})],1),_c('div',{staticClass:"mt-2 ml-12"},[_c('ciam-text',[_vm._v("Duration")])],1),_c('div',[_c('ciam-select',{attrs:{"options":[
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' },
              { label: '13', value: '13' },
              { label: '14', value: '14' },
              { label: '15', value: '15' },
              { label: '16', value: '16' },
              { label: '17', value: '17' },
              { label: '18', value: '18' },
              { label: '19', value: '19' },
              { label: '20', value: '20' },
              { label: '21', value: '21' },
              { label: '22', value: '22' },
              { label: '23', value: '23' },
              { label: '24', value: '24' } ]},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),_c('div',{staticClass:"mt-2"},[_vm._v("Hours")]),_c('div',{staticClass:"w-[500px]"},[_c('ciam-button',{staticClass:"primary",on:{"click":function($event){return _vm.setMaintenanceWindow()}}},[_vm._v("Validate")])],1)])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
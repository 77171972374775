<template>
  <configuration-panel
    :header="$t('deployment.configuration.allowlist.title')"
    :promise="promise"
    :saving-promise="savingPromise"
    :show-upgrade-plan-notice="upgradePlan"
    :deploymentStatus="this.deployment.status"
    @save="save()"
    :featureName="$t('deployment.configuration.allowlist.featureName')"
    anchor="#allowlist">
    <template v-slot:subtitle>
      <ciam-text>{{ $t('deployment.configuration.allowlist.subtitle') }}</ciam-text>
    </template>

    <template>
      <ciam-card-content-line type="split-content-component">
        <template v-slot:label>
          <ciam-text>{{ $t('deployment.configuration.allowlist.scopes.login') }}</ciam-text>
        </template>
        <template v-slot:content>
          <allowlist-editor v-if="deployment.status === 'RUNNING'" :values="form.login" :disabled="false" />
          <allowlist-editor v-else :values="form.login" :disabled="true" />
        </template>
      </ciam-card-content-line>
      <ciam-card-content-line type="split-content-component">
        <template v-slot:label>
          <ciam-text>{{ $t('deployment.configuration.allowlist.scopes.admin') }}</ciam-text>
        </template>
        <template v-slot:content>
          <allowlist-editor v-if="deployment.status === 'RUNNING'" :values="form.admin" :disabled="false" />
          <allowlist-editor v-else :values="form.admin" :disabled="true" />
        </template>
      </ciam-card-content-line>
    </template>
  </configuration-panel>
</template>

<script>
import ConfigurationPanel from '@/pages/deployments/configurations/ConfigurationPanel';
import DeploymentService from '@/pages/deployments/DeploymentService';
import AllowlistEditor from '@/pages/deployments/configurations/allowlist/AllowlistEditor';
import { Notification } from 'vue-notifyjs';
import i18n from '@/i18n';

export default {
  name: 'Allowlist',
  components: {
    AllowlistEditor,
    ConfigurationPanel,
  },
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      promise: Promise.resolve(),
      savingPromise: Promise.resolve(),
      form: {},
      upgradePlan: false,
      problem: null,
    };
  },
  methods: {
    save() {
      this.savingPromise = DeploymentService.setAllowlistConfiguration(this.deployment.id, this.form).then((result) =>
        Notification.notify({
          message: i18n.t('deployment.configuration.notification'),
          type: 'info',
        })
      );
    },
  },
  mounted() {
    this.promise = DeploymentService.getAllowlistConfiguration(this.deployment.id).then(
      (configuration) => (this.form = configuration)
    );
  },
};
</script>

<style scoped></style>

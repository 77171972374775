import { problemAwareHttp, rawHttp } from '@/http';
import { is } from 'ramda';

export default class EnvironmentVariablesService {
  /**
   * @param {String} deploymentId UUID
   * @returns {Promise<Array<{name: String, value: String, type: String>>}
   */
  static getAll(deploymentId) {
    return problemAwareHttp.get(`/deployments/${deploymentId}/envvars`).then((r) => {
      return r.data.variables;
    });
  }

  /**
   * @param {String} deploymentId UUID
   * @param {Array<{name: String, value: String, type: String}>} environmentVariables environment variable key part
   * @returns {Promise<any>}
   */
  static save(deploymentId, environmentVariables) {
    return rawHttp
      .put(
        `/deployments/${deploymentId}/envvars`,
        { variables: environmentVariables },
      )
      .then((response) => {
        if (response.headers['x-tracking-url']) {
          window.open(response.headers['x-tracking-url']);
        }
        return response.data;
      })
      .catch((error) =>
        error.isAxiosError && is(Object, error.response) && is(Object, error.response.data)
          ? Promise.reject(error.response.data)
          : Promise.reject(error),
      );
  }

}
